import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Input, Row, Space} from "antd";
import axios from "axios";

import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {STREAM_SERVER} from "./Constants";

function parseQuery(queryString) {
    console.log('qert neee')
    if (queryString?.length)
        return JSON.parse('{"' + queryString.replace('?&', '').replace('?', '').replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        });
    return {}
}

const PM = () => {
    const {pathname,search} = window.location;
    const [result, setResult] = useState()

    async function fetchData() {
        const response = await axios.get(`${STREAM_SERVER}/api${pathname}${search||''}`);
        return response.data
    }

    useEffect(() => {
        fetchData().then(setResult);
        setTimeout(()=>{
            window.reloadPlugin()
        },3000)
    }, [pathname]);
    return (
        <div style={{
            backgroundColor: '#4f4e52',
            color: 'white',

        }}>
            <div dangerouslySetInnerHTML={{__html: result}}
                  style={{width: '100%', maxWidth: 1600}}>

            </div>


        </div>
    );
};

export default PM;
