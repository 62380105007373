import React, {useEffect, useRef, useState} from 'react';
import ReactHlsPlayer from "react-hls-player";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {ReactFlvPlayer} from 'self-flv-player'
import axios from "axios";
import ReactPlayer from "react-player";
import {Button, Card, Col, Flex, Row} from "antd";

const STREAM_SERVER = window._env_.STREAM_SERVER


const View = () => {
        const {src} = useParams();
        const navigate = useNavigate();
        const [videoInfos, setVideoInfos] = useState()

        const fetchVideoInfo = async src => {
            const response = await axios.get(`${STREAM_SERVER}/api/video/${src}`);
            return response.data
        }
        useEffect(() => {
            fetchVideoInfo(src).then(setVideoInfos)
        }, [src]);

        return (
            <div style={{height: '100%'}}>
                <a href="/" style={{color:'#006ea0'}}>Về Trang Chủ</a>
                {videoInfos?.videoUrl && <div>
                    <ReactPlayer
                        url={`${STREAM_SERVER}/proxy?url=${videoInfos.videoUrl}${'&referer=' + videoInfos.referer}`}
                        playing={true}
                        controls={true}
                        width="100%"
                        height="auto"
                    />
                    <Card title={"Episodes"}>

                        {videoInfos.otherEpisodes?.filter(c => c.episodes.length).map(c => <Row align={"middle"}
                                                                                                style={{marginBottom: 32}} gutter={[16,16]}>
                            <Col lg={2} md={2} sm={24} xs={24} style={{fontWeight:"bold"}}><span style={{textTransform:"uppercase",}}>{c.channel}:</span></Col>
                            <Flex wrap="wrap" gap="small">
                                {c.episodes.map(e => {
                                    const activeEps = e.src.includes(src);
                                    return <Button type={'dashed'} danger={activeEps}
                                                                                        disabled={activeEps}
                                                                                        onClick={() => navigate(e.src)}>{e.title} </Button>;
                                })}
                            </Flex>

                        </Row>)}

                    </Card>
                </div>}
            </div>
        );
    }
;

export default View;
