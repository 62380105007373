import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import View from "./View";
import Movie from "./Movie";
import PM from "./PM";
import ProxyView from "./ProxyView";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {
        path: "/proxy-view",
        element: <ProxyView/>,
    },
    {
        path: "/xem-phim/:src",
        element: <View/>,
    },

    {
        path: "/:path1",
        element:<PM/> ,
    },
    {
        path: "/:path1/:path2",
        element:<PM/> ,
    },,
    {
        path: "/:path1/:path2/:path3",
        element:<PM/> ,
    },
    {
        path: "/",
        element: <PM/>,
    },
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
